import React from "react"
//import { Link } from "gatsby"
import Modal from "./hamburger"

import "./layout.css"
import logo from "./logo.svg"

const Header = () => (
  <>
    <nav className="flex bg-white shadow">
      <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
        <a href="/">
          <img
            className="hidden sm:flex hover:text-red-700 py-1"
            width="54"
            height="54"
            src={logo}
          />
        </a>
        <a href="/">
          <h2
            className="tracking-tight leading-10 font-bold text-gray-900
                       text-xl sm:text-4xl leading-none
                       whitespace-no-wrap py-1"
          >
            <span>Hung Gar Kuen Berlin e.V.</span>
          </h2>
          <h3
            className="tracking-tight leading-10 font-bold text-gray-900 text-l
                       sm:text-2xl leading-none whitespace-no-wrap"
          >
            <span>Kung Fu der Lam-Familie</span>
          </h3>
        </a>
      </div>
      <div className="flex justify-end relative p-4">
        <Modal />
      </div>
    </nav>
  </>
)

export default Header
