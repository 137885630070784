import React from "react"
import DefaultLayout from "../components/default-page-layout"
import Gallery from "@browniebroke/gatsby-image-gallery"
import { IGatsbyImageData } from 'gatsby-plugin-image'
import { graphql, useStaticQuery } from "gatsby"

export default function Gallerie() {
  const data = useStaticQuery(graphql`
    query MyQuery {
      allFile(filter: { sourceInstanceName: { eq: "images" } }) {
        edges {
          node {
            id            
            childImageSharp {
              thumb: gatsbyImageData(
              width: 270
              height: 270
              placeholder: BLURRED)              
              full: gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    
  `)
  const images = data.allFile.edges.map(({ node }) => node.childImageSharp)
  return (
    <>
      <DefaultLayout>
        <Gallery images={images} />
      </DefaultLayout>
    </>
  )
}
