import React from "react"
import PropTypes from "prop-types"
import Header from "./header.js"

import "./layout.css"

const DefaultLayout = ({ children }) => (
  <>
    <div className="container mx-auto h-full max-w-7xl px-4 font-sans antialiased text-gray bg-grey w-full z-0 shadow">
      <Header />
      <div>
        <main>{children}</main>
      </div>
    </div>
  </>
)

DefaultLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default DefaultLayout
